import clsx from "clsx";
import {
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNodeArray,
} from "react";
import styles from "./Card.module.scss";

export type CardProps = {
  header?: false | null | string | JSX.Element | ReactElement | ReactNodeArray;
  fullWidth?: boolean;
} & HTMLAttributes<HTMLDivElement>;

function Card({
  children,
  className,
  fullWidth,
  header,
  ...props
}: PropsWithChildren<CardProps>) {
  return (
    <div
      className={clsx(styles.card, fullWidth && styles.fullWidth, className)}
      {...props}
    >
      {isValidElement(header) ? (
        header
      ) : header ? (
        <div className={styles.header}>{header}</div>
      ) : null}
      {children}
    </div>
  );
}

export default Card;
