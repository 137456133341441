export function mapNextAuthErrorId(
  id: string
): keyof IntlMessages["util"]["error"] {
  switch (id) {
    case "CredentialsSignin":
      return "signin_error";
    case "EmailSignin":
      return "email_error";
    default:
      return "default_error";
  }
}
