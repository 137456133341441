import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCsrfToken, signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslations } from "use-intl";
import { useAuthContext } from "../../context/AuthContext";
import useUtilTranslations from "../../hooks/useUtilTranslations";
import { mapNextAuthErrorId } from "../../util/nextAuthError";
import Button from "../Button/Button";
import Input from "../Input/Input";
import styles from "./AuthForm.module.scss";

export type FormState = "init" | "loading" | "finished" | "error";

export type LoginFormProps = {
  onStateChange: (state: FormState) => void;
};

function LoginForm({ onStateChange }: LoginFormProps) {
  const t = useTranslations("components.Auth");
  const tUtil = useUtilTranslations();
  const [csrfToken, setCsrfToken] = useState<string | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string[] | null>(null);
  const router = useRouter();
  const [showPassword, setShowPassword] = useState(false);
  const { c } = useAuthContext();

  useEffect(() => {
    getCsrfToken().then((token) => setCsrfToken(token));
  }, []);

  useEffect(() => {
    if (router.query && "error" in router.query) {
      const errorId = router.query.error;
      if (!errorId) {
        setErrorMsg(null);
      } else if (Array.isArray(errorId)) {
        const errMsg = errorId.map((e) => mapNextAuthErrorId(e));
        setErrorMsg(errMsg);
      } else {
        setErrorMsg([mapNextAuthErrorId(errorId)]);
      }
    }
  }, [router.query]);

  const goToRegister = async () => {
    await router.push("/auth/register" + location.search);
  };

  const goToPasswordReset = async () => {
    await router.push("/auth/reset_password" + location.search);
  };

  const handleCredentialsSubmit: FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    onStateChange("loading");
    const formData = new FormData(event.currentTarget);
    const body: Record<string, any> = {};
    formData.forEach((v, k) => {
      body[k] = v;
    });
    const q = new URLSearchParams(location.search);
    let callbackUrl;
    if ((callbackUrl = q.get("callbackUrl")) == null) {
      callbackUrl = (c.login ?? "/dashboard") + location.search;
    }
    const resp = await signIn("credentials", {
      redirect: false,
      callbackUrl,
      email: body.email,
      password: body.password,
    });
    onStateChange("finished");
    console.log(resp);
    if (!resp || !resp.ok) {
      onStateChange("error");
      if (resp?.error) setErrorMsg([mapNextAuthErrorId(resp.error)]);
    } else {
      await router.replace(resp.url ?? "/dashboard");
    }
  };

  return (
    <div className={styles.content}>
      {errorMsg && (
        <div className={styles.error}>
          {errorMsg.map((e) => (
            <span key={e} className={styles.message}>
              {
                // @ts-ignore
                tUtil(`error.${e}`)
              }
            </span>
          ))}
        </div>
      )}
      <form className={styles.form} onSubmit={handleCredentialsSubmit}>
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        <Input
          label={t("form.email")}
          inputProps={{
            name: "email",
            type: "email",
            required: true,
          }}
        />
        <Input
          label={t("form.password")}
          inputProps={{
            name: "password",
            type: showPassword ? "text" : "password",
            required: true,
          }}
          suffix={
            <FontAwesomeIcon
              className={styles.suffix}
              icon={showPassword ? faEye : faEyeSlash}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          }
        />
        <Button type="submit">{t("form.action.signin")}</Button>
      </form>
      <hr />
      <Button onClick={goToPasswordReset}>{t("reset_password")}</Button>
      <hr />
      <p>{t("new_account")}</p>
      <Button onClick={goToRegister}>{tUtil("action.register")}</Button>
    </div>
  );
}

export default LoginForm;
