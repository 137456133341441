import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import {
  InputHTMLAttributes,
  LabelHTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from "react";
import { useFormContext } from "react-hook-form";
import styles from "./Input.module.scss";

export type InputProps = {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  label?: string;
  asTextArea?: boolean;
  labelClassName?: string;
  inputContainerClassName?: string;
  suffix?: ReactNode;
} & LabelHTMLAttributes<HTMLLabelElement>;

function Input({
  asTextArea,
  children,
  className,
  label,
  labelClassName,
  inputContainerClassName,
  inputProps,
  suffix,
  ...labelProps
}: PropsWithChildren<InputProps>) {
  const formContext = useFormContext();
  const { className: inputClassName } = inputProps ?? {};

  return (
    <label {...labelProps} className={className}>
      {label && (
        <div className={clsx(styles.label, labelClassName)}>{label}</div>
      )}
      <div className={clsx(styles.inputContainer, inputContainerClassName)}>
        {asTextArea ? (
          <textarea
            {...(inputProps as any)}
            className={clsx(inputClassName, styles.input)}
          />
        ) : (
          <input
            {...inputProps}
            className={clsx(inputClassName, styles.input)}
          />
        )}
        {suffix && <div className={styles.suffix}>{suffix}</div>}
      </div>
      {inputProps?.name && formContext != null && (
        <ErrorMessage
          name={inputProps?.name}
          render={({ message }) => (
            <div className={styles.error_message}>{message}</div>
          )}
        />
      )}
      {children}
    </label>
  );
}

export default Input;
