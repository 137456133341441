import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { useState } from "react";
import LoginForm, { FormState } from "../../src/components/Auth/LoginForm";
import Card from "../../src/components/Card/Card";
import Loading from "../../src/components/common/Loading";
import useSessionUser from "../../src/hooks/useSessionUser";
import useUtilTranslations from "../../src/hooks/useUtilTranslations";
import { getStaticPropsForMessages } from "../../src/util/utilServer";
import { HGFPage } from "../../types/util";
import styles from "./auth.module.scss";

export type LoginProps = {};

const Login: HGFPage<LoginProps> = ({}: LoginProps) => {
  const tUtil = useUtilTranslations();
  const { sessionStatus: status, userData, isLoading } = useSessionUser();
  const [formState, setFormState] = useState<FormState>("init");
  const router = useRouter();

  const handleFormStateChange = (state: FormState) => {
    setFormState(state);
  };

  let content = <LoginForm onStateChange={handleFormStateChange} />;
  if (status === "loading") {
    content = <Loading />;
  } else if (
    status === "authenticated" &&
    formState === "init" &&
    !isLoading &&
    !userData
  ) {
    signOut();
  } else if (status === "authenticated" && formState === "init") {
    router.push("/dashboard" + window.location.search);
    content = (
      <div>
        <p>{tUtil("redirecting")}</p>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <Card className={styles.card}>
        <h3>{tUtil("action.login")}</h3>
        {content}
      </Card>
    </div>
  );
};

export default Login;

Login.Layout = {
  hideNavigation: true,
};

export const getStaticProps = getStaticPropsForMessages([]);
